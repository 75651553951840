exports.components = {
  "component---src-build-pages-account-js": () => import("./../../../src/buildPages/account.js" /* webpackChunkName: "component---src-build-pages-account-js" */),
  "component---src-build-pages-cookie-js": () => import("./../../../src/buildPages/cookie.js" /* webpackChunkName: "component---src-build-pages-cookie-js" */),
  "component---src-build-pages-home-js": () => import("./../../../src/buildPages/home.js" /* webpackChunkName: "component---src-build-pages-home-js" */),
  "component---src-build-pages-login-js": () => import("./../../../src/buildPages/login.js" /* webpackChunkName: "component---src-build-pages-login-js" */),
  "component---src-build-pages-privacy-js": () => import("./../../../src/buildPages/privacy.js" /* webpackChunkName: "component---src-build-pages-privacy-js" */),
  "component---src-build-pages-refund-js": () => import("./../../../src/buildPages/refund.js" /* webpackChunkName: "component---src-build-pages-refund-js" */),
  "component---src-build-pages-terms-js": () => import("./../../../src/buildPages/terms.js" /* webpackChunkName: "component---src-build-pages-terms-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */)
}

